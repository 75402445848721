import React from "react"
import footerIcone from "../images/logoFooter.png"
import { Link } from "gatsby"
typeof window !== "undefined" && require("boxicons")

export default function Footer() {
  return (
    <div className="footer">
      <div className="grid lg:grid-cols-2 grid-cols-1 md:gap-64 text-center">
        <Link to="/" className="ml-16 md:w-1/2 md:mr-4 mr-10 my-16">
          <img
            alt="Inovação Usinagem e Modelação"
            className="h6"
            src={footerIcone}
          />
        </Link>
        <div
          className="col-span-1 lg:col-start-2 md:mt-8 md:text-right md:mr-24 text-center mb-12"
          style={{ fontSize: "15px" }}
        >
          <div className="mt-4">Ligue para nós:</div>
          <a href="tel:+554734738147" className="text-4xl">
            (47) 3473-8147
          </a>
          <div className="mt-4">Rua Willy Schossland, 396 - Bairro Iririú</div>
          <div>CEP 89224-061 - Joinville, SC</div>
        </div>
        {/* <div
          className="col-span-1 mt-8"
          style={{ fontSize: "15px" }}
        >
          <div className="my-3">Bivver Empreendimentos Imobiliários</div>
          <div className="my-3">Endereço: Rua Iririú, 847, Joinville, SC</div>
          <div className="my-3">CEP: 89221-515</div>
        </div>
      */}
      </div>
      <div className="mx-3">
        <hr style={{ height: "1px", backgroundColor: "whitesmoke" }} />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12 m-6 md:my-10">
        <div className="text-center">
          <b style={{ fontFamily: "Source Sans Pro" }} className="text-xl ">
            Inovação
          </b>
          <br />
          <br />
          <Link to="/empresa#nossahistoria" className="barraFinal">
            Nossa história
          </Link>
          <br />
          <br />
          <Link to="/empresa#missao" className="barraFinal">
            Missão
          </Link>
          <br />
          <br />
          <Link to="/empresa#visao" className="barraFinal">
            Visão
          </Link>
          <br />
          <br />
          <Link to="/empresa#valores" className="barraFinal">
            Valores
          </Link>
        </div>
        <div className="text-center">
          <b style={{ fontFamily: "Source Sans Pro" }} className="text-xl">
            Nossas Máquinas
          </b>
          <br />
          <br />
          <Link to="/estrutura#centrosdeusinagem" className="barraFinal">
            Centros de usinagem CNC
          </Link>
          <br />
          <br />
          <Link to="/estrutura#fresas" className="barraFinal">
            Fresas
          </Link>
          <br />
          <br />
          <Link to="/estrutura#rosqueadeira" className="barraFinal">
            Rosqueadeira
          </Link>
          <br />
          <br />
          <Link to="/estrutura#furadeira" className="barraFinal">
            Furadeira
          </Link>
          <br />
          <br />
          <Link to="/estrutura#furadeira" className="barraFinal">
            Plaina
          </Link>
        </div>
        <div className="md:ml-12 ml-32">
          <b style={{ fontFamily: "Source Sans Pro" }} className="text-xl">
            Redes sociais:
          </b>
          <div className="flex mt-2">
            <a
              className="ml-1 barraFinal flex"
              href="https://wa.me/5547996872019"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="bx bxl-whatsapp text-3xl"></i>{" "}
              <div className="text-xl ml-2 mt-1">(47) 99687-2019</div>
            </a>
          </div>
          <div className="flex mt-2">
            <a
              className="ml-1 barraFinal flex"
              href="https://www.linkedin.com/company/inovação-usinagem-e-modelação/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="bx bxl-linkedin text-3xl"></i>
              <div className="text-xl ml-2 mt-2">Inovação</div>
            </a>
          </div>
          <div className="flex mt-2">
            <a
              className="ml-1 barraFinal flex"
              href="https://www.facebook.com/Inovação-Usinagem-101076848999692"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="bx bxl-facebook text-3xl"></i>
              <div className="text-xl ml-2 mt-2">Inovação Usinagem</div>
            </a>
          </div>
          <div className="flex mt-2">
            <a
              className="ml-1 barraFinal flex"
              href="https://www.instagram.com/inovacao_usinagem/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="bx bxl-instagram text-3xl"></i>
              <div className="text-xl ml-2 mt-2">inovacao_usinagem</div>
            </a>
          </div>
        </div>
      </div>
      <div className="mx-3"></div>
      <section
        style={{
          fontSize: "0.85rem",
          backgroundColor: "rgb(8,8,20)",
          color: "gray",
        }}
        className="p-2 text-right"
      >
        <div>
          © 2021 Inovação Usinagem e Modelação. Todos os direitos reservados |
          Desenvolvido por{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/in/ricardo-reinert-572a96145/"
          >
            Ricardo Reinert Karnopp
          </a>
        </div>
      </section>
    </div>
  )
}
