import React from "react"
import ContactButton from "./contactButton"
import { Affix } from "antd"

export default function FixedBar() {
  return (
    <Affix className="fixedBar" offsetBottom={0}>
      <ContactButton className="p-10" />
    </Affix>
  )
}
