/* eslint-disable react/jsx-key */
import Link from "gatsby-link"
import PropTypes from "prop-types"
import React from "react"

typeof window !== "undefined" && require("boxicons")
const Navigation = ({ isExpanded, data }) => {
  return (
    <nav className={`nav md:inline-flex ${isExpanded ? `block` : `hidden`}`}>
      <ul className="md:flex">
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/estrutura">Estrutura</Link>
        </li>
        <li>
          <Link to="/produtos">Produtos</Link>
        </li>
        <li>
          <Link to="/empresa">Empresa</Link>
        </li>
        <li>
          <Link to="/contato">Contato</Link>
        </li>
      </ul>
    </nav>
  )
}
Navigation.propTypes = {
  isExpanded: PropTypes.bool,
  setLoadingPage: PropTypes.func,
  data: PropTypes.array,
}

export default Navigation
