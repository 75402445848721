import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "./footer"
import "./layout.css"
import FixedBar from "./fixedBar"
import processos from "../images/processos-de-usinagem.png"
import cnc from "../images/cnc.png"
import molde from "../images/molde.png"
import injecao from "../images/injecao.png"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Layout = ({
  subtitle,
  children,
  titlePage,
  carrossel,
  titleMargin,
  contactButton,
}) => {
  return (
    <>
      <Header siteTitle="Inovação Usinagem" />
      {carrossel && (
        <Slider
          style={{ overflow: "hidden" }}
          dots
          infinite
          autoplay
          autoplaySpeed={3000}
          slidesToShow={1}
          slidesToScroll={1}
          className="-mb-6"
          pauseOnFocus={false}
          pauseOnHover={false}
        >
          <img alt="Inovação Usinagem e Modelação" src={processos} />
          <img alt="Inovação Usinagem e Modelação" src={cnc} />
          <img alt="Inovação Usinagem e Modelação" src={molde} />
          <img alt="Inovação Usinagem e Modelação" src={injecao} />
        </Slider>
      )}
      {titlePage && (
        <div
          className="pt-10 self-center text-center font-bold titlepage"
          style={{
            height: subtitle ? "16rem" : "10rem",
          }}
        >
          <div className="text-4xl">{titlePage}</div>
          <div
            style={{
              margin: `1rem ${titleMargin}%`,
              backgroundColor: "rgb(8, 8, 34)",
              height: "0.15rem",
            }}
          />
          {subtitle && (
            <h3 className="md:mx-64 mx-6 md:mt-12">
              A Inovação, além de ser referência em usinagem e modelação, tem o
              orgulho de apresentar o seu produto próprio...
            </h3>
          )}
        </div>
      )}
      <div
        style={{
          margin: "0 auto",
          padding: `0 1.0rem 1.0rem`,
          maxWidth: "80%",
        }}
      >
        <main>{children}</main>
      </div>
      {contactButton && <FixedBar />}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
