import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import HomeIcone from "../images/logoinovacao.png"
import CapaHeader from "../images/capaHeader.png"
import Navigation from "./navigation"
import Menu from "../images/menu.png"

const Header = ({ siteTitle }) => {
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <header>
      <div
        id="topo"
        className="lg:flex lg:justify-between"
        style={{
          backgroundColor: "rgb(8, 8, 34)",
        }}
      >
        <div className="hidden lg:flex">
          <Link to="/" className="ml-16 lg:w-1/3 mr-4 mt-6">
            <img alt="Inovação Usinagem e Modelação" src={HomeIcone} />
          </Link>
        </div>
        <div>
          <Navigation {...{ isExpanded }} />
        </div>
      </div>
      <div
        id="topo"
        style={{
          background: `url(${CapaHeader})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className={`flex ${isExpanded && "-mt-40 h-40"}`}
      >
        <Link
          to="/"
          className={`block ${isExpanded && "hidden"} lg:hidden w-2/3 m-1`}
        >
          <img alt="Inovação Usinagem e Modelação" src={HomeIcone} />
        </Link>
        <button
          className="block lg:hidden ml-auto px-3 py-2 text-white"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <img alt="|||" src={Menu} className="h-6 mr-3" />
        </button>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
