import React from "react"
import { Link, navigate } from "gatsby"

export default function ContactButton({ text, submit }) {
  return (
    <button className='w-full' type="submit" onClick={() => !submit && navigate("/contato")}>
      <div className="contactButton text-2xl self-center">
        {text ? text : "Entre em Contato!"}
      </div>
    </button>
  )
}
